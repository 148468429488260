<template>
    <div class="startup-page-content">
    </div>
</template>

<script>
    export default {
        mounted() {
            this.initialize()
        },
        methods: {
            initialize() {
                const loading = this.$loading({
                    lock: true,
                    text: '请稍后......',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                setTimeout(() => {
                    axios.get('/verify?token=' + localStorage.getItem('token'))
                        .then((res) => {
                            loading.close();
                            if (res.data.code === 1) {
                                const systemType = localStorage.getItem('systemType');
                                if (systemType == 0) {
                                    this.$router.replace('/access_home')
                                }
                                if (systemType == 1) {
                                    this.$router.replace('/door_home')
                                }
                                if (systemType == 2) {
                                    this.$router.replace('/admin_user')
                                }
                            }
                        })


                }, 600)
            },

        }
    }
</script>

<style>
    .startup-page-content {
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background-image: url(../../assets/bg.webp);
        background-size: 100% 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
</style>
